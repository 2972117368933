import React, { useState } from 'react';
import './Accounts.scss';
import Accpersonal from '../../components/Accpersonal/Accpersonal';

const Accounts = () => {
    const [selectedBlock, setSelectedBlock] = useState(null);

    const handleBlockClick = (blockNumber) => {
        setSelectedBlock(blockNumber);
    };

    // Функція для скидання вибору блоку
    const handleResetBlock = () => {
        setSelectedBlock(null);
    };

    return (
        <div className='accounts'>
            {!selectedBlock ? (
                <div className="accounts__wrapper">
                    <div 
                        className="adv__list-item shine-button"
                        style={{ width: '250px', height: '150px', margin: '10px', cursor: 'pointer' }} 
                        onClick={() => handleBlockClick(1)}
                    >
                        Замовлення для <span>Антона</span> 
                    </div>
                    <div 
                        className="adv__list-item shine-button"
                        style={{ width: '250px', height: '150px', margin: '10px', cursor: 'pointer' }} 
                        onClick={() => handleBlockClick(2)}
                    >
                        Замовлення для <span>Артема</span> 
                    </div>
                </div>
            ) : (
                <Accpersonal 
                    selectedBlock={selectedBlock} 
                    onResetBlock={handleResetBlock}  
                />
            )}
        </div>
    );
}

export default Accounts;
