// src/components/CategoriesManager.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CategoriesManager.scss';

const CategoriesManager = ({ onSelectCategory }) => {
  const [categories, setCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Стан для редагування категорії
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [editedCategoryName, setEditedCategoryName] = useState('');

  // Отримання списку категорій при завантаженні компонента
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const res = await axios.get('https://spptesti-2e99c730e09e.herokuapp.com/api/categories');
      setCategories(res.data);
      setError(null);
    } catch (err) {
      console.error('Помилка при отриманні категорій:', err);
      setError('Не вдалося отримати категорії.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    if (!newCategoryName.trim()) return;

    try {
      const res = await axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/categories', { name: newCategoryName.trim() });
      setCategories([res.data, ...categories]);
      setNewCategoryName('');
      setError(null);
    } catch (err) {
      console.error('Помилка при створенні категорії:', err);
      setError(err.response?.data?.message || 'Не вдалося створити категорію.');
    }
  };

  const handleDeleteCategory = async (id) => {
    if (!window.confirm('Ви впевнені, що хочете видалити цю категорію?')) return;

    try {
      await axios.delete(`https://spptesti-2e99c730e09e.herokuapp.com/api/categories/${id}`);
      setCategories(categories.filter((category) => category._id !== id));
      setError(null);
    } catch (err) {
      console.error('Помилка при видаленні категорії:', err);
      setError(err.response?.data?.message || 'Не вдалося видалити категорію.');
    }
  };

  const openEditModal = (category) => {
    setCategoryToEdit(category);
    setEditedCategoryName(category.name);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setCategoryToEdit(null);
    setEditedCategoryName('');
  };

  const handleEditCategory = async (e) => {
    e.preventDefault();
    if (!editedCategoryName.trim()) return;

    try {
      const res = await axios.put(`https://spptesti-2e99c730e09e.herokuapp.com/api/categories/${categoryToEdit._id}`, { name: editedCategoryName.trim() });
      setCategories(categories.map((cat) => (cat._id === categoryToEdit._id ? res.data : cat)));
      setError(null);
      closeEditModal();
    } catch (err) {
      console.error('Помилка при редагуванні категорії:', err);
      setError(err.response?.data?.message || 'Не вдалося редагувати категорію.');
    }
  };

  return (
    <div className="categories-manager">
      <h3 className="categories-manager__title">Виберіть категорію інструментів</h3>
      <form onSubmit={handleAddCategory} className="add-category-form">
        <input
          type="text"
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
          placeholder="Нова категорія"
        />
        <button type="submit">
          <i className="material-icons">send</i>
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
      {loading ? (
        <p>Завантаження категорій...</p>
      ) : (
        <ul className="categories-list">
          {categories.map((category) => (
            <li key={category._id}>
              <button onClick={() => onSelectCategory(category)} className="category-item">
                {category.name}
              </button>
              <button onClick={() => openEditModal(category)} className="edit-category-button">
                <i className="material-icons">edit</i>
              </button>
              <button onClick={() => handleDeleteCategory(category._id)} className="delete-category-button">
                <i className="material-icons">delete</i>
              </button>
            </li>
          ))}
        </ul>
      )}

      {/* Модальне вікно для редагування категорії */}
      {isEditModalOpen && categoryToEdit && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Редагувати назву категорії</h3>
            <form onSubmit={handleEditCategory}>
              <div className="form-group">
                <label htmlFor="editedCategoryName">Назва категорії:</label>
                <input
                  type="text"
                  id="editedCategoryName"
                  value={editedCategoryName}
                  onChange={(e) => setEditedCategoryName(e.target.value)}
                  required
                />
              </div>
              <div className="modal-buttons">
                <button type="submit" className="submit-button">
                  <i className="material-icons">save</i>
                </button>
                <button type="button" onClick={closeEditModal} className="cancel-button">
                  <i className="material-icons">close</i>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoriesManager;
