// src/components/AntikPage.jsx

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './AntikPage.scss';
import { AuthContext } from '../../context/AuthContext';
import CategoriesManager from '../../pages/CategoriesManager/CategoriesManager';

const AntikPage = () => {
  const { role, name } = useContext(AuthContext);

  // Стан для вибраної категорії (цілого об'єкта)
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [image, setImage] = useState(null);
  const [link, setLink] = useState('');
  const [text, setText] = useState('');
  const [blocks, setBlocks] = useState([]);
  const [usersListOpen, setUsersListOpen] = useState({});
  const [newComments, setNewComments] = useState({});
  const [editingComment, setEditingComment] = useState({});
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState(null);

  // Обробка зміни зображення
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  // Обробка зміни посилання
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  // Обробка зміни тексту
  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  // Обробка вибору категорії через CategoriesManager
  const handleCategorySelection = (category) => {
    console.log('Selected Category:', category);
    setSelectedCategory(category);
  };

  // Відправка форми
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCategory) {
      alert('Будь ласка, виберіть категорію перед додаванням блоку.');
      return;
    }

    const formData = new FormData();
    if (image) formData.append('image', image);
    formData.append('link', link);
    formData.append('text', text);
    formData.append('category', selectedCategory._id); // Додаємо тільки _id категорії

    console.log('Submitting form with Category ID:', selectedCategory._id);

    try {
      const res = await axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/antik', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Додано новий блок:', res.data);
      setBlocks([res.data, ...blocks]);
      // Очищення полів вводу
      setImage(null);
      setLink('');
      setText('');
    } catch (err) {
      console.error('Помилка при додаванні блоку:', err);
      alert(err.response?.data?.message || 'Помилка при додаванні блоку.');
    }
  };

  // Завантаження блоків при виборі категорії
  useEffect(() => {
    if (!selectedCategory) {
      setBlocks([]);
      return;
    }

    const fetchBlocks = async () => {
      try {
        const res = await axios.get('https://spptesti-2e99c730e09e.herokuapp.com/api/antik', {
          params: { category: selectedCategory._id }, // Використовуємо _id категорії
        });
        console.log('Отримані блоки:', res.data);
        // Переконуємося, що поле comments є масивом
        const updatedBlocks = res.data.map((block) => ({
          ...block,
          comments: Array.isArray(block.comments) ? block.comments : [],
        }));
        setBlocks(updatedBlocks);
      } catch (err) {
        console.error('Помилка при отриманні блоків:', err);
        alert('Не вдалося отримати блоки.');
      }
    };
    fetchBlocks();
  }, [selectedCategory]);

  // Функція для перетворення base64 в зображення
  const renderImage = (base64Data, mimeType) => {
    return `data:${mimeType};base64,${base64Data}`;
  };

  // Обробка зміни чекбоксу користувача
  const handleUserCheckboxChange = async (blockId, userId, checked) => {
    try {
      await axios.put(`https://spptesti-2e99c730e09e.herokuapp.com/api/antik/blocks/${blockId}/users/${userId}`, { checked: !checked });
      // Оновлення стану блоків
      setBlocks((prevBlocks) =>
        prevBlocks.map((block) => {
          if (block._id === blockId) {
            return {
              ...block,
              users: block.users.map((user) =>
                user._id === userId ? { ...user, checked: !checked } : user
              ),
            };
          }
          return block;
        })
      );
    } catch (err) {
      console.error('Помилка при оновленні стану користувача:', err);
      alert('Не вдалося оновити стан користувача.');
    }
  };

  // Видалення блоку
  const handleDelete = async (id) => {
    if (!window.confirm('Ви впевнені, що хочете видалити цей блок?')) return;

    try {
      await axios.delete(`https://spptesti-2e99c730e09e.herokuapp.com/api/antik/blocks/${id}`);
      setBlocks(blocks.filter((block) => block._id !== id));
    } catch (err) {
      console.error('Помилка при видаленні блоку:', err);
      alert(err.response?.data?.message || 'Помилка при видаленні блоку.');
    }
  };

  // Тогл списку користувачів
  const toggleUsersList = (blockId) => {
    setUsersListOpen((prevState) => ({
      ...prevState,
      [blockId]: !prevState[blockId],
    }));
  };

  // Відкриття модального вікна з коментарями
  const openCommentsModal = (block) => {
    setSelectedBlock(block);
    setIsCommentsModalOpen(true);
  };

  // Закриття модального вікна
  const closeCommentsModal = () => {
    setIsCommentsModalOpen(false);
    setSelectedBlock(null);
  };

  // Обробка додавання коментаря
  const handleAddComment = async (blockId) => {
    const commentText = newComments[blockId];
    if (!commentText) return;

    try {
      const res = await axios.post(`https://spptesti-2e99c730e09e.herokuapp.com/api/antik/blocks/${blockId}/comments`, {
        text: commentText,
        userName: name,
      });
      console.log('Додано коментар:', res.data);
      // Оновлення коментарів блоку
      setBlocks((prevBlocks) =>
        prevBlocks.map((block) => {
          if (block._id === blockId) {
            return {
              ...block,
              comments: [...block.comments, res.data],
            };
          }
          return block;
        })
      );

      // Оновлюємо selectedBlock
      if (selectedBlock && selectedBlock._id === blockId) {
        setSelectedBlock((prevBlock) => ({
          ...prevBlock,
          comments: [...prevBlock.comments, res.data],
        }));
      }

      // Очищення поля вводу
      setNewComments({ ...newComments, [blockId]: '' });
    } catch (err) {
      console.error('Помилка при додаванні коментаря:', err);
      alert(err.response?.data?.message || 'Помилка при додаванні коментаря.');
    }
  };

  // Обробка редагування коментаря
  const handleEditComment = async (blockId, commentId) => {
    const commentText = editingComment[commentId];
    if (!commentText) return;

    try {
      const res = await axios.put(`https://spptesti-2e99c730e09e.herokuapp.com/api/antik/blocks/${blockId}/comments/${commentId}`, {
        text: commentText,
      });
      console.log('Оновлено коментар:', res.data);
      // Оновлення коментарів блоку
      setBlocks((prevBlocks) =>
        prevBlocks.map((block) => {
          if (block._id === blockId) {
            return {
              ...block,
              comments: block.comments.map((comment) =>
                comment._id === commentId ? res.data : comment
              ),
            };
          }
          return block;
        })
      );

      // Оновлюємо selectedBlock
      if (selectedBlock && selectedBlock._id === blockId) {
        setSelectedBlock((prevBlock) => ({
          ...prevBlock,
          comments: prevBlock.comments.map((comment) =>
            comment._id === commentId ? res.data : comment
          ),
        }));
      }

      // Очищення стану редагування
      setEditingComment({ ...editingComment, [commentId]: undefined });
    } catch (err) {
      console.error('Помилка при редагуванні коментаря:', err);
      alert('Не вдалося оновити коментар.');
    }
  };

  // Обробка видалення коментаря
  const handleDeleteComment = async (blockId, commentId) => {
    if (!window.confirm('Ви впевнені, що хочете видалити цей коментар?')) return;

    try {
      await axios.delete(`https://spptesti-2e99c730e09e.herokuapp.com/api/antik/blocks/${blockId}/comments/${commentId}`);
      console.log('Видалено коментар:', commentId);
      // Оновлення коментарів блоку
      setBlocks((prevBlocks) =>
        prevBlocks.map((block) => {
          if (block._id === blockId) {
            return {
              ...block,
              comments: block.comments.filter((comment) => comment._id !== commentId),
            };
          }
          return block;
        })
      );

      // Оновлюємо selectedBlock
      if (selectedBlock && selectedBlock._id === blockId) {
        setSelectedBlock((prevBlock) => ({
          ...prevBlock,
          comments: prevBlock.comments.filter((comment) => comment._id !== commentId),
        }));
      }
    } catch (err) {
      console.error('Помилка при видаленні коментаря:', err);
      alert('Не вдалося видалити коментар.');
    }
  };

  return (
    <div className="antik-container">
      {!selectedCategory ? (
        <CategoriesManager onSelectCategory={handleCategorySelection} />
      ) : (
        <>
          {/* Кнопка для скидання вибору категорії */}
          <button onClick={() => setSelectedCategory(null)} className="reset-category-button">
            <i className='material-icons'>arrow_back</i>Змінити категорію
          </button>
          
          {/* Відображення вибраної категорії */}
          <div className="selected-category">
            Вибрана категорія: <span>{selectedCategory.name}</span>
          </div>

          {/* Форма для додавання нового блоку */}
          <form className="antik-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="image">Завантажити зображення:</label>
              <input type="file" accept="image/*" onChange={handleImageChange} id="image" />
            </div>

            <div className="form-group">
              <label htmlFor="link">Посилання:</label>
              <input
                type="text"
                id="link"
                value={link}
                onChange={handleLinkChange}
                placeholder="Введіть посилання на антік"
              />
            </div>

            <div className="form-group">
              <label htmlFor="text">Текст:</label>
              <input
                type="text"
                id="text"
                value={text}
                onChange={handleTextChange}
                placeholder="Введіть назву антіка"
              />
            </div>

            <button type="submit" className="submit-button">
              Додати до списку
            </button>
          </form>

          {/* Контейнер для відображення блоків */}
          <div className="blocks-container">
            {blocks.map((block) => {
              // Рахуємо кількість позначених чекбоксів
              const checkedCount = block.users
                ? block.users.filter((user) => user.checked).length
                : 0;

              // Рахуємо кількість коментарів
              const commentsCount = block.comments ? block.comments.length : 0;

              return (
                <div className="block-card" key={block._id}>
                  <div className="block-card-box-img">
                    {block.imageData && block.mimeType && (
                      <img
                        src={renderImage(block.imageData, block.mimeType)}
                        alt="Uploaded"
                        className="block-image"
                      />
                    )}
                  </div>

                  {block.text && <p className="block-text">{block.text}</p>}
                  <div className="download-link-comment-box">
                    {block.link && (
                      <div className="download-link-comment-box-in">
                        <p>Посилання на завантаження:</p>
                        <a
                          href={block.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block-link"
                        >
                          <i className="material-icons">link</i>
                        </a>
                      </div>
                    )}
                    <div className="download-link-comment-box-in">
                      <p>Залишити коментар:</p>
                      <button
                        className="comments-button"
                        onClick={() => openCommentsModal(block)}
                      >
                        <i className="material-icons">comment</i>
                        <span className="counter-comment">{commentsCount}</span>
                      </button>
                    </div>
                  </div>
                  <button onClick={() => handleDelete(block._id)} className="delete-button">
                    <i className="material-icons">delete</i>
                  </button>

                  <button
                    className="toggle-users-button"
                    onClick={() => toggleUsersList(block._id)}
                  >
                    {usersListOpen[block._id] ? 'Приховати користувачів' : 'Показати користувачів'}
                    <span className="counter">{checkedCount}</span>
                  </button>

                  {/* Список користувачів */}
                  <div
                    className={`users-list ${usersListOpen[block._id] ? 'open' : 'closed'}`}
                  >
                    {block.users && block.users.length > 0 ? (
                      block.users.map((user, index) => (
                        <div className="landing-page__form-checkboxes" key={user._id || index}>
                          <label>
                            <input
                              className="check-fake"
                              type="checkbox"
                              checked={user.checked}
                              onChange={() =>
                                handleUserCheckboxChange(block._id, user._id, user.checked)
                              }
                            />
                            <span>{user.name}</span>
                          </label>
                        </div>
                      ))
                    ) : (
                      <p>Немає користувачів</p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}

      {/* Модальне вікно з коментарями */}
      {isCommentsModalOpen && selectedBlock && (
        <div className="comments-modal">
          <div className="comments-modal-content">
            <button className="close-button" onClick={closeCommentsModal}>
              &times;
            </button>
            <h3 className="comments-title">
              Відгуки та коментарі для - <span>{selectedBlock.text}</span>
            </h3>

            {Array.isArray(selectedBlock.comments) && selectedBlock.comments.length > 0 ? (
              selectedBlock.comments.map((comment, index) => (
                <div className="comment" key={comment._id || index}>
                  {editingComment[comment._id] !== undefined ? (
                    <div className="edit-comment">
                      <input
                        type="text"
                        value={editingComment[comment._id]}
                        onChange={(e) =>
                          setEditingComment({
                            ...editingComment,
                            [comment._id]: e.target.value,
                          })
                        }
                      />
                      <button
                        onClick={() => handleEditComment(selectedBlock._id, comment._id)}
                      >
                        <i className="material-icons">save</i>
                      </button>
                      <button
                        onClick={() =>
                          setEditingComment({ ...editingComment, [comment._id]: undefined })
                        }
                      >
                        <i className="material-icons">close</i>
                      </button>
                    </div>
                  ) : (
                    <div className="comment-content">
                      <div className="comment-content-user">
                        <span className="comment-user">{comment.userName}:</span>
                      </div>
                      <div className="comment-content-text">
                        <span className="comment-text">{comment.text}</span>
                      </div>
                      <div className="comment-buttons">
                        <button
                          className="edit-button"
                          onClick={() =>
                            setEditingComment({ ...editingComment, [comment._id]: comment.text })
                          }
                        >
                          <i className="material-icons">edit</i>
                        </button>
                        <button
                          className="delete-button-1"
                          onClick={() => handleDeleteComment(selectedBlock._id, comment._id)}
                        >
                          <i className="material-icons">delete</i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p className='no-comment'>Немає коментарів</p>
            )}
            <div className="add-comment">
              <input
                type="text"
                value={newComments[selectedBlock._id] || ''}
                onChange={(e) =>
                  setNewComments({ ...newComments, [selectedBlock._id]: e.target.value })
                }
                placeholder="Додати коментар"
              />
              <button onClick={() => handleAddComment(selectedBlock._id)}>
                <i className="material-icons">send</i>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AntikPage;
