import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import DropOptions from "../../components/DropOptions/DropOptions";
import StatusDropdown from "../../components/StatusDropdown/StatusDropdown";
import { AuthContext } from "../../context/AuthContext";
import "./Accpersonal.scss";
import moment from 'moment';

const contentData = {
  1: {
    title: "Anton",
    description: "Account Order for Anton",
    buttons: [
      { id: 1, name: "Pavlo S" },
      { id: 2, name: "Eugeniy S" },
      { id: 3, name: "Izzet S" },
      { id: 4, name: "Alex P" },
      { id: 5, name: "Alex H" },
      { id: 6, name: "Alex M" },
      { id: 7, name: "Maksym Zh" },
    ],
    listTypeGeo: "list1",
    listTypeSim: "list2",
    listTypeAdditional1: "list5", // Новий список опцій
    listTypeAdditional2: "list6", // Новий список опцій
  },
  2: {
    title: "Artem",
    description: "Account Order for Artem",
    buttons: [
      { id: 1, name: "Pavlo S" },
      { id: 2, name: "Eugeniy S" },
      { id: 3, name: "Izzet S" },
      { id: 4, name: "Alex P" },
      { id: 5, name: "Alex H" },
      { id: 6, name: "Alex M" },
      { id: 7, name: "Maksym Zh" },
    ],
    listTypeGeo: "list3",
    listTypeSim: "list4",
    listTypeAdditional1: "list7", // Новий список опцій
    listTypeAdditional2: "list8", // Новий список опцій
  },
  // Додайте більше блоків за потреби
};

const Accpersonal = ({ selectedBlock, onResetBlock }) => {
  const [activeButton, setActiveButton] = useState(null);
  const content = contentData[selectedBlock];

  // Стан для полів вводу
  const [numberValue, setNumberValue] = useState("");
  const [textValue, setTextValue] = useState("");
  const [radioValue, setRadioValue] = useState("");
  const [dropdownValue1, setDropdownValue1] = useState("");
  const [dropdownValue2, setDropdownValue2] = useState("");
  const [dropdownValue3, setDropdownValue3] = useState(""); // Новий дропдаун
  const [dropdownValue4, setDropdownValue4] = useState(""); // Новий дропдаун
  const [submittedData, setSubmittedData] = useState([]);
  const [allSubmittedData, setAllSubmittedData] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState(null);
  const [pendingCounts, setPendingCounts] = useState({});
  const [openDropdown, setOpenDropdown] = useState(null);
  const [priorityValue, setPriorityValue] = useState(1);

  const [isEditing, setIsEditing] = useState(null);
  const [editData, setEditData] = useState({
    number: "",
    text: "",
    radio: "",
    dropdown1: "",
    dropdown2: "",
    dropdown3: "", // Новий дропдаун
    dropdown4: "", // Новий дропдаун
    status: "",
    priority: 1, // Додаємо пріоритет
  });
  // Стан для оновлення DropOptions
  const [optionsUpdated, setOptionsUpdated] = useState(0);

  const { role, name } = useContext(AuthContext);
  const userRole = role || "User";
  const allowedRoles = ["Admin", "Farm"];

  // Додаткові стани для опцій Geo та SIM
  const [geoOptions, setGeoOptions] = useState([]);
  const [simOptions, setSimOptions] = useState([]);
  const [additional1Options, setAdditional1Options] = useState([]); // Новий дропдаун
  const [additional2Options, setAdditional2Options] = useState([]); // Новий дропдаун

  useEffect(() => {
    if (content && content.buttons && content.buttons.length > 0) {
      // Якщо кнопка не активна, встановлюємо першу кнопку за замовчуванням
      if (!activeButton) {
        setActiveButton(content.buttons[0].id);
      }
    } else {
      // Якщо немає кнопок, забезпечуємо null для activeButton
      setActiveButton(null);
    }

    // Скидаємо всі необхідні стани при зміні блоку
    setNumberValue("");
    setTextValue("");
    setRadioValue("");
    setDropdownValue1("");
    setDropdownValue2("");
    setDropdownValue3(""); // Скидаємо нові дропдауни
    setDropdownValue4(""); // Скидаємо нові дропдауни
    setSubmittedData([]);
    setAllSubmittedData([]);
    setIsEditing(null);
    setShowDeleteConfirm(false);
    setIndexToDelete(null);
    setEditData({
      number: "",
      text: "",
      radio: "",
      dropdown1: "",
      dropdown2: "",
      dropdown3: "",
      dropdown4: "",
      status: "",
    });
  }, [content]);

  const priorityLabels = {
    1: 'Дуже низький',
    2: 'Низький',
    3: 'Середній',
    4: 'Високий',
    5: 'Дуже високий',
  };

  // Функція для завантаження всіх даних
  const fetchAllData = async () => {
    if (content && content.buttons) {
      try {
        const allDataArrays = await Promise.all(content.buttons.map(async (button) => {
          const response = await axios.get(
            `https://spptesti-2e99c730e09e.herokuapp.com/api/farm/${content.title}/${button.id}`
          );
          const data = response.data || [];
          return { buttonId: button.id, data };
        }));
        const allData = allDataArrays.flatMap(item => item.data);
        setAllSubmittedData(allData);

        const counts = {};
        allDataArrays.forEach(item => {
          const pendingCount = item.data.filter(order => order.status === 'Pending').length;
          counts[item.buttonId] = pendingCount;
        });
        setPendingCounts(counts);
      } catch (error) {
        console.error("Помилка при завантаженні всіх даних:", error);
        setAllSubmittedData([]);
        setPendingCounts({});
      }
    }
  };

  // Завантаження всіх даних при зміні розділу
  useEffect(() => {
    fetchAllData();
  }, [content]);

  // Завантаження даних при активній кнопці
  useEffect(() => {
    if (activeButton) {
      setSubmittedData([]); // Скидаємо submittedData до порожнього масиву
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://spptesti-2e99c730e09e.herokuapp.com/api/farm/${content.title}/${activeButton}`
          );
          setSubmittedData(response.data || []);
        } catch (error) {
          console.error("Помилка при завантаженні даних:", error);
          setSubmittedData([]);
        }
      };
      fetchData();
    } else {
      setSubmittedData([]);
    }
    // Скидаємо стани редагування при зміні activeButton
    setIsEditing(null);
    setEditData({
      number: "",
      text: "",
      radio: "",
      dropdown1: "",
      dropdown2: "",
      dropdown3: "",
      dropdown4: "",
      status: "",
    });
  }, [activeButton, content.title]);

  // Функція для завантаження опцій Geo та SIM
  const fetchGeoOptions = async () => {
    try {
      const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/options/${content.listTypeGeo}`);
      setGeoOptions(response.data);
    } catch (error) {
      console.error('Помилка завантаження Geo опцій:', error);
    }
  };

  const fetchSimOptions = async () => {
    try {
      const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/options/${content.listTypeSim}`);
      setSimOptions(response.data);
    } catch (error) {
      console.error('Помилка завантаження SIM опцій:', error);
    }
  };

  // Функції для завантаження опцій нових дропдаунів
  const fetchAdditional1Options = async () => {
    try {
      const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/options/${content.listTypeAdditional1}`);
      setAdditional1Options(response.data);
    } catch (error) {
      console.error('Помилка завантаження опцій Додаткове поле 1:', error);
    }
  };

  const fetchAdditional2Options = async () => {
    try {
      const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/options/${content.listTypeAdditional2}`);
      setAdditional2Options(response.data);
    } catch (error) {
      console.error('Помилка завантаження опцій Додаткове поле 2:', error);
    }
  };

  // Завантаження опцій
  useEffect(() => {
    fetchGeoOptions();
    fetchSimOptions();
    fetchAdditional1Options();
    fetchAdditional2Options();
  }, [content.listTypeGeo, content.listTypeSim, content.listTypeAdditional1, content.listTypeAdditional2, optionsUpdated]);

  // Обчислення кількості замовлень за статусами
  const getStatusCounts = () => {
    const counts = { Pending: 0, 'In progress': 0, Done: 0 };

    allSubmittedData.forEach((order) => {
      if (order.status === 'Pending') {
        counts.Pending += 1;
      } else if (order.status === 'In progress') {
        counts['In progress'] += 1;
      } else if (order.status === 'Done') {
        counts.Done += 1;
      }
    });

    return counts;
  };

  const statusCounts = getStatusCounts();

  const getStatusClassName = (status) => {
    switch (status) {
      case 'Pending':
        return 'status--waiting';
      case 'In progress':
        return 'status--in-progress';
      case 'Done':
        return 'status--completed';
      default:
        return '';
    }
  };

  // Функція для видалення замовлення
  const handleDelete = async (index) => {
    try {
      const updatedData = submittedData.filter((_, i) => i !== index);
      setSubmittedData(updatedData);

      // Оновлюємо allSubmittedData на сервері
      await axios.put(
        `https://spptesti-2e99c730e09e.herokuapp.com/api/farm/${content.title}/${activeButton}`,
        updatedData
      );

      // Завантажуємо оновлені опції
      await fetchSimOptions();
      await fetchAdditional1Options();
      await fetchAdditional2Options();

      // Оновлюємо стан для перерендерингу компонентів
      setOptionsUpdated(prev => prev + 1);

      // Оновлюємо allSubmittedData локально
      fetchAllData();
    } catch (error) {
      console.error(
        "Помилка при видаленні даних:",
        error.response ? error.response.data : error.message
      );
      alert("Сталася помилка при видаленні даних.");
    }
  };

  // Функція для підтвердження видалення
  const handleDeleteRequest = (index) => {
    setIndexToDelete(index);
    setShowDeleteConfirm(true);
  };

  // Функція для скасування видалення
  const handleCancelDelete = () => {
    setShowDeleteConfirm(false);
    setIndexToDelete(null);
  };

  // Функція для підтвердження видалення
  const handleConfirmDelete = async () => {
    if (indexToDelete !== null) {
      await handleDelete(indexToDelete);
      setShowDeleteConfirm(false);
      setIndexToDelete(null);
    }
  };

  // Функція для редагування замовлення
  const handleEdit = (index) => {
    const dataToEdit = submittedData[index];
    setEditData({
      number: dataToEdit.number,
      text: dataToEdit.text,
      radio: dataToEdit.radio,
      dropdown1: dataToEdit.dropdown1,
      dropdown2: dataToEdit.dropdown2,
      dropdown3: dataToEdit.dropdown3, // Новий дропдаун
      dropdown4: dataToEdit.dropdown4, // Новий дропдаун
      status: dataToEdit.status,
      priority: dataToEdit.priority || 1,
    });
    setIsEditing(index);
  };

  // Функція для збереження редагування
  const handleSaveEdit = async () => {
    try {
      const updatedData = [...submittedData];

      // Зберігаємо попередню кількість акаунтів
      const previousNumber = submittedData[isEditing].number;

      updatedData[isEditing] = {
        ...editData,
        previousNumber: previousNumber,
        name: submittedData[isEditing].name,
        date: submittedData[isEditing].date,
        editedBy: name || "Unknown",
        editDate: moment().format("HH:mm, DD.MM.YYYY"),
        orderNumber: submittedData[isEditing].orderNumber,
      };

      setSubmittedData(updatedData);
      setIsEditing(null);

      // Відправляємо оновлені дані на сервер
      await axios.put(
        `https://spptesti-2e99c730e09e.herokuapp.com/api/farm/${content.title}/${activeButton}`,
        updatedData
      );
      await fetchSimOptions();
      await fetchAdditional1Options();
      await fetchAdditional2Options();

      // Оновлюємо стан для перерендерингу компонентів
      setOptionsUpdated(prev => prev + 1);

      // Оновлюємо всі дані
      fetchAllData();
    } catch (error) {
      console.error("Помилка при збереженні даних:", error.response ? error.response.data : error.message);
      alert("Сталася помилка при збереженні даних.");
    }
  };

  // Функція для зміни статусу
  const handleStatusChange = async (index, newStatus) => {
    try {
      const updatedData = [...submittedData];
      updatedData[index].status = newStatus;
      setSubmittedData(updatedData);

      // Оновлюємо дані на сервері
      await axios.put(
        `https://spptesti-2e99c730e09e.herokuapp.com/api/farm/${content.title}/${activeButton}`,
        updatedData
      );

      // Оновлюємо allSubmittedData
      fetchAllData();
    } catch (error) {
      console.error(
        "Помилка при зміні статусу:",
        error.response ? error.response.data : error.message
      );
      alert("Сталася помилка при зміні статусу.");
    }
  };

  // Функції для встановлення вибраних опцій
  const handleSelectDropdown1 = (optionId) => {
    setDropdownValue1(optionId); // Зберігаємо ID опції
    setOpenDropdown(null); // Закриваємо дропдаун
  };

  const handleSelectDropdown2 = (optionId) => {
    setDropdownValue2(optionId); // Зберігаємо ID опції
    setOpenDropdown(null); // Закриваємо дропдаун
  };

  const handleSelectDropdown3 = (optionId) => {
    setDropdownValue3(optionId);
    setOpenDropdown(null);
  };

  const handleSelectDropdown4 = (optionId) => {
    setDropdownValue4(optionId);
    setOpenDropdown(null);
  };

  // Функції для відкриття/закриття дропдаунів
  const handleDropdownOpen = (dropdownName) => {
    setOpenDropdown(dropdownName);
  };

  const handleDropdownClose = () => {
    setOpenDropdown(null);
  };

  // Функція для створення нового замовлення
  const handleSubmit = async () => {
    if (!dropdownValue1 || !dropdownValue2 || !numberValue) {
      alert("Будь ласка, виберіть опції та введіть кількість.");
      return;
    }

    try {
      const parsedNumber = Number(numberValue);
      if (isNaN(parsedNumber) || parsedNumber <= 0) {
        alert("Будь ласка, введіть дійсне число для кількості.");
        return;
      }

      // Визначаємо наступний порядковий номер
      const nextOrderNumber = allSubmittedData.length > 0
        ? Math.max(...allSubmittedData.map(o => o.orderNumber || 0)) + 1
        : 1;

      const newOrder = {
        orderNumber: nextOrderNumber,
        number: parsedNumber,
        text: textValue,
        radio: radioValue,
        dropdown1: dropdownValue1,
        dropdown2: dropdownValue2,
        dropdown3: dropdownValue3, // Новий дропдаун
        dropdown4: dropdownValue4, // Новий дропдаун
        priority: priorityValue, // Додаємо пріоритет
        status: "Pending",
        name: name || "Unknown",
        date: moment().format("HH:mm, DD.MM.YYYY"),
      };

      // Відправка замовлення на сервер
      const response = await axios.post(
        `https://spptesti-2e99c730e09e.herokuapp.com/api/farm/${content.title}/${activeButton}`,
        newOrder
      );

      // Оновлення локального стану
      setSubmittedData((prevData) => [...prevData, response.data.order]);

      // Оновлення DropOptions (перезавантаження списку опцій)
      setOptionsUpdated(prev => prev + 1);

      // Оновлення всіх даних
      fetchAllData();

      // Очищення полів вводу
      setNumberValue("");
      setTextValue("");
      setRadioValue("");
      setDropdownValue1("");
      setDropdownValue2("");
      setDropdownValue3(""); // Скидаємо нові дропдауни
      setDropdownValue4(""); // Скидаємо нові дропдауни
      setPriorityValue(1); // Скидаємо пріоритет до 1
    } catch (error) {
      console.error("Помилка при відправці даних:", error.response ? error.response.data : error.message);
      alert(error.response?.data?.message || "Сталася помилка при збереженні даних.");
    }
  };

  // Функції для отримання тексту опцій за ID
  const getGeoTextById = (id) => {
    const option = geoOptions.find(opt => opt._id === id);
    return option ? option.text : 'Немає даних';
  };

  const getSimTextById = (id) => {
    const option = simOptions.find(opt => opt._id === id);
    return option ? option.text : 'Немає даних';
  };

  const getAdditional1TextById = (id) => {
    const option = additional1Options.find(opt => opt._id === id);
    return option ? option.text : 'Немає даних';
  };

  const getAdditional2TextById = (id) => {
    const option = additional2Options.find(opt => opt._id === id);
    return option ? option.text : 'Немає даних';
  };

  const activeButtonName = content.buttons.find(button => button.id === activeButton)?.name || "";

  return (
    <div className="accpersonal">
      {/* Кнопка для повернення до вибору блоку */}
      <button className="back-button" onClick={onResetBlock}>
        <i className="material-icons">arrow_back</i>Farmer Choice
      </button>

      <h2>{content.title}</h2>
      <p>{content.description}</p>

      {/* Секція для відображення кількостей */}
      <div className="accpersonal__status-counts">
        <p>Pending: <span className="status--waiting">{statusCounts.Pending}</span></p>
        <p>In progress: <span className="status--in-progress">{statusCounts['In progress']}</span></p>
        <p>Done:  <span className="status--completed">{statusCounts.Done}</span></p>
      </div>

      <div className="buttons">
        {content.buttons.map((button) => (
          <button
            key={button.id}
            onClick={() => setActiveButton(button.id)}
            className={`button ${activeButton === button.id ? 'active' : ''}`}
          >
            {button.name}
            {(allowedRoles.includes(userRole)) && (
              pendingCounts[button.id] > 0 && (
                <span className="pending-count">{pendingCounts[button.id]}</span>
              )
            )}
          </button>
        ))}
      </div>

      {activeButton && (
        <div className="button-content">
          <div className="rangeinput">
            <p>Пріоритет:</p>
            <input
              type="range"
              min="1"
              max="5"
              value={priorityValue}
              onChange={(e) => setPriorityValue(Number(e.target.value))}
            />
            <span className={`priority-level-${priorityValue}`}>
              {priorityLabels[priorityValue]}
            </span>
          </div>
          <div>
            <label>Count of Accounts</label>
            <input
              type="number"
              value={numberValue}
              onChange={(e) => setNumberValue(e.target.value)}
              min="1"
            />
          </div>

          <div>
            <label>Description</label>
            <input
              type="text"
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
            />
          </div>

          <div className="accpersonal__form-checkboxes">
            <div>
              <label htmlFor="option1">
                <input
                  type="radio"
                  name="landingType"
                  id="option1"
                  value="Прогрітий"
                  checked={radioValue === "Прогрітий"}
                  onChange={(e) => setRadioValue(e.target.value)}
                />
                <span>Прогрітий</span>
              </label>
            </div>
            <div>
              <label htmlFor="option2">
                <input
                  type="radio"
                  name="landingType"
                  id="option2"
                  value="Без прогріву"
                  checked={radioValue === "Без прогріву"}
                  onChange={(e) => setRadioValue(e.target.value)}
                />
                <span>Без прогріву</span>
              </label>
            </div>
          </div>

          <div>
            {/* Існуючі дропдауни */}
            <DropOptions
              label="Гео"
              listType={content.listTypeGeo}
              placeholder="Choice of Geo"
              onSelect={handleSelectDropdown1}
              refresh={optionsUpdated}
              showValue={false}
              isOpen={openDropdown === "geo"}
              onOpen={() => handleDropdownOpen("geo")}
              onClose={handleDropdownClose}
            />

            <DropOptions
              label="Картки"
              listType={content.listTypeSim}
              placeholder="Choice of SIM-card"
              onSelect={handleSelectDropdown2}
              refresh={optionsUpdated}
              isOpen={openDropdown === "sim"}
              onOpen={() => handleDropdownOpen("sim")}
              onClose={handleDropdownClose}
            />

            {/* Нові дропдауни */}
            <DropOptions
              label="Проксі"
              listType={content.listTypeAdditional1}
              placeholder="Choise of proxy"
              onSelect={handleSelectDropdown3}
              refresh={optionsUpdated}
              isOpen={openDropdown === "additional1"}
              onOpen={() => handleDropdownOpen("additional1")}
              onClose={handleDropdownClose}
              showValue={false} // Додаємо це
            />

            <DropOptions
              label="Платформа"
              listType={content.listTypeAdditional2}
              placeholder="Choise of platform"
              onSelect={handleSelectDropdown4}
              refresh={optionsUpdated}
              isOpen={openDropdown === "additional2"}
              onOpen={() => handleDropdownOpen("additional2")}
              onClose={handleDropdownClose}
              showValue={false} // Додаємо це
            />
          </div>

          <button className="accpersonal__button-order" onClick={handleSubmit}>
            Замовити
          </button>

          {/* Відображення замовлень */}
          <div>
            <h3 className="accpersonal__order-title">
              Orders  <span>{activeButtonName && `- ${activeButtonName}`}</span>
            </h3>
            <ul className="accpersonal__order-list">
              {submittedData && submittedData.length > 0 ? (
                [...submittedData].map((data, index) => (
                  <li key={data.orderNumber || index}>
                    {isEditing === index ? (
                      <div className="data-text">
                        {/* Відображення порядкового номера */}

                        <div>
                          <label>Count:</label>
                          <input
                            type="number"
                            value={editData.number}
                            onChange={(e) =>
                              setEditData({
                                ...editData,
                                number: e.target.value,
                              })
                            }
                            min="1"
                          />
                        </div>
                        <div className="input-field">
                          <p>Пріоритет:</p>
                          <p className="range-field">
                            <input
                              type="range"
                              min="1"
                              max="5"
                              value={editData.priority}
                              onChange={(e) => {
                                const newPriority = Number(e.target.value);
                                setEditData({ ...editData, priority: newPriority });
                              }}
                            />
                          </p>
                          <span className={`priority-level-${editData.priority}`}>
                            {priorityLabels[editData.priority]}
                          </span>
                        </div>
                        <div>
                          <label>Description:</label>
                          <input
                            type="text"
                            value={editData.text}
                            onChange={(e) =>
                              setEditData({ ...editData, text: e.target.value })
                            }
                            placeholder="Введіть текст"
                          />
                        </div>

                        <div className="accpersonal__form-checkboxes">
                          <div>
                            <label htmlFor={`edit-option1-${index}`}>
                              <input
                                type="radio"
                                name={`edit-radio-group-${index}`}
                                id={`edit-option1-${index}`}
                                value="Прогрітий"
                                checked={editData.radio === "Прогрітий"}
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    radio: e.target.value,
                                  })
                                }
                              />
                              <span>Прогрітий</span>
                            </label>
                          </div>
                          <div>
                            <label htmlFor={`edit-option2-${index}`}>
                              <input
                                type="radio"
                                name={`edit-radio-group-${index}`}
                                id={`edit-option2-${index}`}
                                value="Без прогріву"
                                checked={editData.radio === "Без прогріву"}
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    radio: e.target.value,
                                  })
                                }
                              />
                              <span>Без прогріву</span>
                            </label>
                          </div>
                        </div>

                        {/* Відображення DropOptions для редагування */}
                        <div>
                          <label>Гео:</label>
                          <DropOptions
                            label="Гео"
                            listType={content.listTypeGeo}
                            placeholder="Choice of Geo"
                            onSelect={(optionId) => {
                              setEditData({
                                ...editData,
                                dropdown1: optionId,
                              });
                              setOpenDropdown(null);
                            }}
                            refresh={optionsUpdated}
                            isOpen={openDropdown === `edit-geo-${index}`}
                            onOpen={() => handleDropdownOpen(`edit-geo-${index}`)}
                            onClose={handleDropdownClose}
                            showValue={false}
                          />
                          <label>Картки:</label>
                          <DropOptions
                            label="Картки"
                            listType={content.listTypeSim}
                            placeholder="Choice of SIM-card"
                            onSelect={(optionId) => {
                              setEditData({
                                ...editData,
                                dropdown2: optionId,
                              });
                              setOpenDropdown(null);
                            }}
                            refresh={optionsUpdated}
                            isOpen={openDropdown === `edit-sim-${index}`}
                            onOpen={() => handleDropdownOpen(`edit-sim-${index}`)}
                            onClose={handleDropdownClose}
                          />
                          <label>Проксі:</label>
                          <DropOptions
                            label="Додаткове поле 1"
                            listType={content.listTypeAdditional1}
                            placeholder="Select an option"
                            onSelect={(optionId) => {
                              setEditData({
                                ...editData,
                                dropdown3: optionId,
                              });
                              setOpenDropdown(null);
                            }}
                            refresh={optionsUpdated}
                            isOpen={openDropdown === `edit-additional1-${index}`}
                            onOpen={() => handleDropdownOpen(`edit-additional1-${index}`)}
                            onClose={handleDropdownClose}
                            showValue={false} // Додаємо це
                          />
                          <label>Платформа:</label>
                          <DropOptions
                            label="Додаткове поле 2"
                            listType={content.listTypeAdditional2}
                            placeholder="Select an option"
                            onSelect={(optionId) => {
                              setEditData({
                                ...editData,
                                dropdown4: optionId,
                              });
                              setOpenDropdown(null);
                            }}
                            refresh={optionsUpdated}
                            isOpen={openDropdown === `edit-additional2-${index}`}
                            onOpen={() => handleDropdownOpen(`edit-additional2-${index}`)}
                            onClose={handleDropdownClose}
                            showValue={false} // Додаємо це
                          />
                        </div>

                        <button
                          className="display-data__icon-button-save"
                          onClick={handleSaveEdit}
                        >
                          <i className="material-icons">save</i>
                        </button>
                      </div>
                    ) : (
                      <div className="display-data">
                        <div className="data-text">
                          {/* Додавання порядкового номера */}
                          <div>
                            <p>№ Замовлення:</p>
                            <span>{data.orderNumber || "Немає даних"}</span>
                          </div>
                          <div>
                            <p>Пріоритет:</p>
                            <h6 className={`priority-level-${data.priority}`}>
                              {priorityLabels[data.priority] || 'Немає даних'}
                            </h6>
                          </div>
                          <div>
                            <p>Кількість:</p>
                            {data.previousNumber ? (
                              <span><h5>{data.previousNumber}</h5> ➔ {data.number}</span>
                            ) : (
                              <span>{data.number || "Немає даних"}</span>
                            )}
                          </div>
                          <section className="data-text-textarea">
                            <p>Опис:</p>
                            <textarea readOnly>
                              {data.text || "Немає даних"}
                            </textarea>
                          </section>
                          <div>
                            <p>Прогрів:</p>
                            <span>{data.radio || "Немає даних"}</span>
                          </div>
                          <div>
                            <p>Гео:</p>
                            <span>{getGeoTextById(data.dropdown1)}</span>
                          </div>
                          <div>
                            <p>SIM-карта:</p>
                            <span>{getSimTextById(data.dropdown2)}</span>
                          </div>
                          <div>
                            <p>Проксі:</p>
                            <span>{getAdditional1TextById(data.dropdown3)}</span>
                          </div>
                          <div>
                            <p>Платформа:</p>
                            <span>{getAdditional2TextById(data.dropdown4)}</span>
                          </div>

                          {/* Додати дату та ім'я */}
                          <div>
                            <p>Ім'я замовника:</p>
                            <span>{data.name || "Немає даних"}</span>
                          </div>
                          <div>
                            <p>Дата додавання:</p>
                            <span>
                              {data.date}
                            </span>
                          </div>
                          <div>
                            <p>Останній редактор:</p>
                            <span>{data.editedBy || "Немає даних"}</span>
                          </div>
                          <div>
                            <p>Дата редагування:</p>
                            <span>{data.editDate || "Немає даних"}</span>
                          </div>
                          <ul className="data-text__status-box">
                            <div className="data-text__status-box-color">
                              <p>Статус:</p>
                              <h4 className={getStatusClassName(data.status)}>
                                {data.status || "Немає даних"}
                              </h4>
                            </div>

                            {(allowedRoles.includes(userRole)) && (
                              <StatusDropdown
                                options={["Pending", "In progress", "Done"]}
                                selected={data.status}
                                onSelect={(value) =>
                                  handleStatusChange(index, value)
                                }
                              />
                            )}
                          </ul>
                        </div>
                        <div className="display-data__icon-button">
                          <button
                            className="display-data__icon-button-edit"
                            onClick={() => handleEdit(index)}
                          >
                            <i className="material-icons">edit</i>
                          </button>
                          <button
                            className="display-data__icon-button-delete"
                            onClick={() => handleDeleteRequest(index)}
                          >
                            <i className="material-icons">delete</i>
                          </button>
                        </div>
                      </div>
                    )}
                  </li>
                ))
              ) : (
                <li>Дані відсутні</li>
              )}
            </ul>
            {showDeleteConfirm && (
              <div className="delete-confirmation">
                <p>Ви впевнені, що хочете видалити це замовлення?</p>
                <button onClick={handleConfirmDelete} className="confirm-button">
                  Так
                </button>
                <button onClick={handleCancelDelete} className="cancel-button">
                  Ні
                </button>
              </div>
            )}
          </div>
        </div>
      )}

    </div>

  );
};

export default Accpersonal;
